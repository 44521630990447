<template>
  <div id="app" class="main-container navbar-sticky flex">
    
    <nav v-if="currentRoutePath !== 'Login'" id="nav" class="sidebar fixed top-0 bottom-0 z-40 flex-none w-[212px] border-r border-black/10 dark:border-white/10 transition-all duration-300">
      <div class="mb-10 flex item-center justify-center logo">
        <img :src="logoSvg" />
      </div>
      <ul class="text-left	relative h-[calc(100vh-58px)] flex flex-col gap-1 overflow-y-auto overflow-x-hidden p-4 py-0">
        <li class="menu nav-item"><router-link to="/">Dashboard</router-link></li>
        <li class="menu nav-item"><router-link to="/servers">Servers</router-link></li>
        <li class="menu nav-item"><router-link to="/logout">Exit</router-link></li>
      </ul>
      
      
    </nav>
    <div class="main-content flex min-h-screen">
      <router-view/>
    </div>
    <div v-if="currentRoutePath === 'ServerDetail'" class="right-sidebar fixed right-0 bg-white dark:bg-black bottom-0 z-50 w-[280px] border-l border-black/10 dark:border-white/10 transition-all duration-300">
        <queue />
    </div>
  </div>
</template>

<script>

import Queue from './components/Queue.vue';
export default {
  components:{
    Queue
  },
  data(){
    return {
      logoSvg: require('./assets/logo.svg')
    }
  },
  mounted(){
    
  },
  computed: {
    currentRoutePath() {
        return this.$route.name;
    }
  }
}
</script>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: rgb(255, 148, 57);
}

#nav a.router-link-exact-active {
  color: #3F2871;
}

#nav .logo img{
  width: 50px;
}
</style>
