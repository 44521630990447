<template>
  <div class="home">
   <h1>Ti diamo il benvenuto</h1>
  </div>
</template>

<script>
// @ is an alias to /src
//import Servers from '@/components/Servers.vue'

export default {
  name: 'Home',
  components: {
    //Servers
  }
}
</script>
