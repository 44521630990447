<template>
    <div class="login row">
        <div v-if="error">{{ error }}</div>
        <form class="mb-4">
            <div class="mb-4"><input v-model="email" type="text" value="" placeholder="Email" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"></div>
            <div class="mb-2"><input v-model="password" type="password" value="" placeholder="Password" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"></div>

            <div v-if="expired" id="expired">
              <p>Challenge expired!</p>
          </div>

            <vue-hcaptcha ref="asyncExecuteHCaptcha" 
            sitekey="4d611834-cd26-4f87-b153-3e1a41ce2ea4"
            @verify="onVerify"
            @expired="onExpire"
            ></vue-hcaptcha>

        

            <button :disabled="verified === false" @click="login()" type="button" class="py-2 px-4 bg-black dark:bg-lightpurple-200 w-full rounded-lg text-white dark:text-black text-lg font-semibold border border-black dark:border-lightpurple-200 hover:bg-transparent dark:hover:bg-transparent hover:text-black dark:hover:text-white transition-all duration-300">Sign In</button>

        
          </form>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import axios from 'axios'
  import VueHcaptcha from '@hcaptcha/vue-hcaptcha';

  const Axios = axios.create({
    baseURL: 'https://console.cloud.integrate.digital/api/'
  })
  export default {
    components: {
      VueHcaptcha 
        },
    name: 'Login',
    data(){
      return {
        error: null,
        verified: false,
        expired: false
      }
    },
  methods: {
    onVerify() {
      this.verified = true
    },
    onExpire() {
            this.verified = false;
        },
    async login() {

      if(!this.verified){
        await this.$refs.asyncExecuteHCaptcha.executeAsync();
      }
      
        //event.preventDefault()
       // const url = 'https://console.cloud.integrate.digital/api/';
        this.success = false;
        //this.error = null;

        try {
          const {data} = await Axios.post(`?task=login`, { email: this.email, password: this.password });
          console.log(data)
          if(data && data.jwt){
            this.success = true;
            localStorage.setItem('signInToken', data.jwt);
            this.$router.push('/')
          }else{
            this.error = data.error;
            this.success = false;
          }
        } catch (err) {
            
          this.error = err.message;
        }
      }
    }
  }
  </script>