<template>
    <h2>Bye bye...!</h2>
</template>
<script>

export default {
    name: 'Logout',
    mounted(){
        localStorage.removeItem('signInToken');
        this.$router.push('/login');
    }
}
</script>
